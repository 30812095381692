import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { Cookies } from 'react-cookie-consent'
import styled, { css } from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { createGlobalStyle } from 'styled-components'

const CookieConsent = styled.div`
  position: fixed;
  bottom: -500px;
  bottom: ${({ isOpen }) => (isOpen === true ? '0px' : '-500px')};
  width: 100%;
  display: none;
  display: ${({ isOpen }) => (isOpen !== undefined ? 'block' : 'none')};
  z-index: 15;
  margin: 0 auto;
  left: 0;
  transition: bottom 0.5s linear;
`

const CookieConsentWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 30px;
  background: #1b394e;
  opacity: 0.75;
  width: ${({ isTablet }) => (isTablet ? 'initial' : '80rem')};
`

const DisclaimerText = styled.div`
  font-size: ${({ isTablet }) => (isTablet ? '12px' : '16px')};
  color: #fff;
  text-align: center;
  padding-right: 10px;
  font-weight: 100;
  margin-bottom: 2rem;
`

const DisclaimerSubText = styled.div`
  font-size: ${({ isTablet }) => (isTablet ? '12px' : '16px')};
  color: #fff;
  text-align: center;
  padding-right: 10px;
  font-weight: 100;
  margin-bottom: 1.5rem;
`

const ButtonAccept = styled.div`
  font-size: ${({ isTablet }) => (isTablet ? '12px' : '16px')};
  min-width: 0;
  width: 150px;
  margin: 0 auto;
  padding: 5px 5px;
  color: #fff;
  background: #2d96cd;
  text-align: center;
  cursor: pointer;
  font-weight: 100;

  &:hover {
    background: white;
    color: #2d96cd;
  }
`

const CloseConsent = styled.div`
  position: absolute;
  top: 13px;
  right: -18px;
  width: 50px;
  cursor: pointer;

  span {
    font-size: 2.4rem;
    color: white;
  }
`

const GlobalStyle = createGlobalStyle`
  /* ${({ loadThaiFont }) => {
    if (loadThaiFont) {
      css`
        @font-face {
          font-family: Superstore;
          font-stretch: normal;
          font-style: normal;
          font-weight: 300;
          src: url(../fonts/ford-th/Superstore-Regular.otf) format('otf'),
            url(../fonts/ford-th/Superstore-Regular.otf) format('otf');
        }

        * {
          font-family: FordAntenna !important;
        }

        body {
          .fds-app {
            font-family: Superstore !important;
          }
        }
      `
    } else {
      css`
        @font-face {
          font-family: FordAntenna;
          font-stretch: normal;
          font-style: normal;
          font-weight: 300;
          src: url(../fonts/ford/antenna-light.woff2) format('woff2'), url(../fonts/ford/antenna-light.woff) format('woff');
        }
        @font-face {
          font-family: FordAntennaRegular;

          src: url(../fonts/ford/antenna-regular.woff2) format('woff2'), url(../fonts/ford/antenna-regular.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: normal;
          font-style: normal;
          font-weight: 500;
          src: url(../fonts/ford/antenna-medium.woff2) format('woff2'), url(../fonts/ford/antenna-medium.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: normal;
          font-style: normal;
          font-weight: 700;
          src: url(../fonts/ford/antenna-bold.woff2) format('woff2'), url(../fonts/ford/antenna-bold.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: normal;
          font-style: italic;
          font-weight: 300;
          src: url(../fonts/ford/antenna-light-italic.woff2) format('woff2'),
            url(../fonts/ford/antenna-light-italic.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: normal;
          font-style: italic;
          font-weight: 400;
          src: url(../fonts/ford/antenna-regular-italic.woff2) format('woff2'),
            url(../fonts/ford/antenna-regular-italic.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: normal;
          font-style: italic;
          font-weight: 500;
          src: url(../fonts/ford/antenna-medium-italic.woff2) format('woff2'),
            url(../fonts/ford/antenna-medium-italic.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: normal;
          font-style: italic;
          font-weight: 700;
          src: url(../fonts/ford/antenna-bold-italic.woff2) format('woff2'),
            url(../fonts/ford/antenna-bold-italic.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: condensed;
          font-style: normal;
          font-weight: 300;
          src: url(../fonts/ford/antenna-cond-light.woff2) format('woff2'),
            url(../fonts/ford/antenna-cond-light.woff) format('woff');
        }
        @font-face {
          font-family: FordAntennaCondRegular;
          font-stretch: condensed;
          font-style: normal;
          font-weight: 400;
          src: url(../fonts/ford/antenna-cond-regular.woff2) format('woff2'),
            url(../fonts/ford/antenna-cond-regular.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: condensed;
          font-style: normal;
          font-weight: 500;
          src: url(../fonts/ford/antenna-cond-medium.woff2) format('woff2'),
            url(../fonts/ford/antenna-cond-medium.woff) format('woff');
        }
        @font-face {
          font-family: FordAntenna;
          font-stretch: condensed;
          font-style: normal;
          font-weight: 700;
          src: url(../fonts/ford/antenna-cond-bold.woff2) format('woff2'),
            url(../fonts/ford/antenna-cond-bold.woff) format('woff');
        }
      `
    }
  }} */
`
const botmakerSiteMap = {
  guia_360_ar: 'FYAPG7XM82',
  guia_360_br: 'CYYBLU76XN',
  ch_360_guide: '24486USYRA',
  pe_360_guide: 'HEQDQZLKUI',
  guia_360_co: 'WFVAU0HNG0'
}

export const Template = props => {
  const breakpoints = useBreakpoint()

  const location = useLocation()
  const {
    isHome,
    title,
    description,
    language,
    subtitle,
    has_cookie_consent,
    imageSiteURl,
    site,
    privacy_policy_text,
    privacy_policy_subtext,
    privacy_policy_accept_button_text
  } = props
  const [answerConsent, setAnswerConsent] = useState(!!Cookies.get('accepted-cooking-consent') ? true : false)
  const [openConsent, setOpenConsent] = useState()
  const [loadVimeoTracking, setLoadVimeoTracking] = useState(false)

  const acceptCookiesPolicy = () => {
    Cookies.set('accepted-cooking-consent', true)
    Cookies.set('gatsby-gdpr-google-analytics', true)
    Cookies.set('gatsby-gdpr-google-tagmanager', true)
    Cookies.set('gatsby-gdpr-hotjar', true)

    initializeAndTrack(location)
    setLoadVimeoTracking(true)
    setOpenConsent(false)
  }

  const rejectCookiesPolicy = () => {
    Cookies.set('accepted-cooking-consent', false)
    Cookies.set('gatsby-gdpr-google-analytics', false)
    Cookies.set('gatsby-gdpr-google-tagmanager', false)
    Cookies.set('gatsby-gdpr-hotjar', false)

    setOpenConsent(false)
  }

  useEffect(() => {
    const acceptedConsent = Cookies.get('accepted-cooking-consent')
    if (acceptedConsent === undefined) {
      setOpenConsent(true)
    } else {
      setOpenConsent(undefined)
    }

    // if (!has_cookie_consent) {
    acceptCookiesPolicy()
    // }
  }, [])

  const botmakerScript = useMemo(() => {
    const sites = Object.keys(botmakerSiteMap)

    if (!site || !sites.includes(site)) return null

    const botmakerId = botmakerSiteMap[site]

    return (
      <script
        dangerouslySetInnerHTML={{
          __html: `
        (function () {
        
            let js = document.createElement('script');
        
            js.type = 'text/javascript';
        
            js.async = 1;
        
            js.src = 'https://go.botmaker.com/rest/webchat/p/${botmakerId}/init.js';
        
            document.body.appendChild(js);
        
        })();`
        }}
      ></script>
    )
  }, [site])

  return (
    <>
      <GlobalStyle loadThaiFont={props.loadThaiFont} />

      <div className="fds-app js-fds">
        <Helmet>
          <meta charSet="utf-8" />
          <meta http-equiv="Content-Language" content={language} />
          <title>{`${title}${!!subtitle ? ' | ' + subtitle : ''}`}</title>
          <meta name="description" content={description} />
          <meta property="og:site_name" content={title} />
          <meta property="og:title" content={`${title}${!!subtitle ? ' | ' + subtitle : ''}`} />
          <meta property="og:description" content={description} />
          {imageSiteURl && <meta property="og:image" itemprop="image" content={imageSiteURl} />}
          <meta property="og:type" content="website" />

          <script src="https://player.vimeo.com/api/player.js"></script>
        </Helmet>
        <div className="fds-guidelines">
          <div className="fds-guidelines__layout-content fds-gutter--gapless">
            <div className="fds-guidelines__layout-main" style={{ backgroundColor: isHome ? 'white' : '#f4f4f4' }}>
              <div style={{ maxWidth: !props.noMaxWidth ? '1440px' : 'initial', margin: '0 auto', minWidth: '300px' }}>
                {props.children}
              </div>
            </div>
          </div>
        </div>

        {/* {loadVimeoTracking && (
        <Helmet>
          <script type="text/javascript" defer="defer" src="https://extend.vimeocdn.com/ga/126763814.js"></script>
        </Helmet>
      )} */}

        {botmakerScript}

        {!answerConsent && has_cookie_consent && (
          <CookieConsent isOpen={openConsent}>
            <CookieConsentWrapper isTablet={breakpoints.l}>
              {/* <CloseConsent>
                <span
                  onClick={() => {
                    rejectCookiesPolicy()
                  }}
                  className="fds-icon .fds-icon--20 fds-font--ford-icons__clear"
                ></span>
              </CloseConsent> */}
              <DisclaimerText isTablet={breakpoints.l}>{privacy_policy_text}</DisclaimerText>

              <DisclaimerSubText isTablet={breakpoints.l}>{privacy_policy_subtext}</DisclaimerSubText>
              <ButtonAccept
                isTablet={breakpoints.l}
                onClick={() => {
                  acceptCookiesPolicy()
                }}
              >
                {privacy_policy_accept_button_text}
              </ButtonAccept>
            </CookieConsentWrapper>
          </CookieConsent>
        )}
      </div>
    </>
  )
}
